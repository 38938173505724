// Sound Czech JS

$(function() {

    // Listing item toggle
    (function() {
        var $toggle = $('.listing-item-header'),
            itemClass = 'listing-item',
            itemBodyClass = 'listing-item-body-wrapper',
            openClass = 'listing-item-open',
            slideDuration = 300;

        function toggleItem($item, state) {
            var $body = $('.' + itemBodyClass, $item);

            if (state) {
                $body.slideDown(slideDuration);
                $item.addClass(openClass);
                history.replaceState({}, '', location.pathname + '#' + $item[0].id);
            } else {
                $body.slideUp(slideDuration);
                $item.removeClass(openClass);
                history.replaceState({}, '', location.pathname);
            }
        }

        $toggle.on('click', function(e) {
            var $item = $(this).closest('.' + itemClass);

            toggleItem($item, !$item.hasClass(openClass));
        })

        // Open item from location.hash
        var $hash = $(location.hash);
        if ($hash.hasClass(itemClass)) {
            toggleItem($hash, true);

            $('html, body').animate({
                scrollTop: $hash.offset().top
            }, 500);
        }
    })();

    // Filter toggle
    (function() {
        var $filters = $('.filters .filter'),
            $filterToggles = $('.filter-toggle', $filters),
            filterOpenClass = 'filter-open';

        function closeFilters() {
            $filters.removeClass(filterOpenClass);
        }

        $filterToggles.on('click', function(e) {
            $(this).parent().siblings().removeClass(filterOpenClass);
            $(this).parent().toggleClass(filterOpenClass);
        });

        // Esc press
        $(document).on('keyup', function(e) {
            if (e.keyCode === 27) { // Esc
                closeFilters();
            }
        });

        // Click outside filter
        $(document).on('click touchend', function(e) {
            if (!$(e.target).closest('.filters').length) {
                closeFilters();
            }
        });
    })();

    // Filter map
    (function() {
        var $regionLinks = $('[data-highlight-region]'),
            $map = $('.map'),
            regionHighlightClass = 'region-highlight';

        $regionLinks.on('mouseover mouseout', function(e) {
            var region = this.dataset.highlightRegion,
                mouseout = (e.type === 'mouseout');

            $map.find('.region').each(function(index, element) {
                var state = mouseout ? false : (element.id === region);
                $(element).toggleClass(regionHighlightClass, state);
            });

        });
    })();

});